<template>
  <div>
    <header
      :style="`background-color: ${backgroundColor}; color: #FFFFFF;`">
      <nav>
        <v-container>
          <v-row class="d-flex justify-space-between align-center">
            <v-col cols="2">
              <div class="text-left">
                <v-btn
                  icon
                  dark
                  @click="$router.push('/liff/booking')"
                >
                  <v-icon>mdi-arrow-left</v-icon>
                </v-btn>
              </div>
            </v-col>
            <v-col cols="8">
              <div class="d-flex justify-center align-center">
                <div
                  style="font-size: 20px;">
                  {{ $t('booking.bookingGroup') }}
                </div>
                <!-- <div>
                  <v-btn
                    v-if="$i18n.locale === 'en'"
                    class="ma-2"
                    drak
                    icon
                    color="secondary"
                    @click="changeLanguage('th')"
                  >
                    <img src="@/assets/thailand.png">
                  </v-btn>
                  <v-btn
                    v-else
                    class="ma-2"
                    drak
                    icon
                    color="secondary"
                    @click="changeLanguage('en')"
                  >
                    <img src="@/assets/england.png">
                  </v-btn>
                </div> -->
              </div>
            </v-col>
            <v-col cols="2"></v-col>
          </v-row>
        </v-container>
      </nav>
    </header>
    <Loading v-if="isLoading" />
    <v-container v-if="isBooking">
      <Calendar
        v-if="reRender"
        :schedule="allSchedule"
        :fulldate="allDateFull"
        :available="allDateAvailable"
        @input="getSchedule($event)"
      />
      <div ref="booking">
        <v-row>
          <v-col
            v-if="startTime && endTime"
            cols="12">
            <v-card outlined>
              <v-card-title>
                {{ $t('booking.bookingSummary') }}
              </v-card-title>
              <v-container>
                <v-form
                  ref="form"
                  lazy-validation>
                  <v-row>
                    <v-col cols="3">
                      {{ $t('global.branch') }}
                    </v-col>
                    <v-col cols="9">
                      {{ branch === 'trang' ? $t('global.trang') : $t('global.sathon') }}
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="3">
                      {{ $t('global.date') }}
                    </v-col>
                    <v-col cols="9">
                      {{ formatDate(date) }}
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="3">
                      {{ $t('global.time') }}
                    </v-col>
                    <v-col cols="9">
                      <span v-if="startTime && endTime">
                        {{ formatSchedule(startTime, endTime) }}
                      </span>
                    </v-col>
                  </v-row>
                  <v-row v-if="isMember">
                    <v-col cols="3">
                      {{ $t('global.name') }}
                    </v-col>
                    <v-col cols="9">
                      {{ member.firstname }} {{ member.lastname }}
                    </v-col>
                  </v-row>
                </v-form>
              </v-container>
            </v-card>
          </v-col>
        </v-row>
      </div>
      <div class="mt-10">
        <v-btn
          v-if="startTime && endTime"
          color="#23b574"
          class="mb-2"
          block
          dark
          depressed
          @click="checkConfirm">
          {{ $t('global.next') }}
        </v-btn>
        <v-btn
          v-else
          color="#23b574"
          class="mb-2 disabled"
          block
          disabled
          depressed
        >
          {{ $t('booking.nextButtonClick') }}
        </v-btn>
      </div>
    </v-container>
    <v-dialog v-model="isSchedule">
      <v-card>
        <v-card-title class="header justify-center mb-4">
          <span class="font-weight-bold">{{ formatDate(dateMock) }}</span>
        </v-card-title>
        <v-container>
          <div
            v-for="(item, index) in items"
            :key="index"
            class="header px-4 mb-6">
            <v-row>
              <v-col
                class="mt-1"
                cols="6">
                {{ formatSchedule(item.startTime, item.endTime) }}
              </v-col>
              <v-col
                cols="6"
              >
                <v-btn
                  v-if="item.slot >= item.max || item.isOpen === false"
                  width="120"
                  dark
                  depressed
                  color="#af5a5f">
                  {{ $t('global.full') }}
                </v-btn>
                <v-btn
                  v-else
                  width="120"
                  dark
                  depressed
                  color="#23b574"
                  @click="setTime(item.scheduleId, item.startTime, item.endTime, item.max, item.slot)">
                  {{ $t('global.blank') }} {{ item.max - item.slot }}
                </v-btn>
              </v-col>
            </v-row>
          </div>
        </v-container>
      </v-card>
    </v-dialog>
    <BookingGroupForm
      v-if="isBookingForm"
      :slotbooking="slot"
      :date="date"
      :start-time="startTime"
      :end-time="endTime"
      :max="max"
      @input="onAddOrder($event)" />
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import Loading from '@/components/Loading.vue'
import formatDate from '@/utils/formatDate'
import Calendar from '@/components/Calendar.vue'
import BookingGroupForm from '@/components/Book/BookingGroupForm.vue'

export default {
  components: {
    Calendar,
    Loading,
    BookingGroupForm
  },
  data () {
    return {
      isBooking: true,
      isSchedule: false,
      isBookingForm: false,
      isLoading: false,
      backgroundColor: '#3BAEF8',
      allSchedule: [],
      allDateFull: [],
      allDateAvailable: [],
      reRender: true,
      member: '',
      max: '',
      slot: '',
      nowDate: this.$dayjs().format('YYYY-MM-DD'),
      children: [],
      date: '',
      dateMock: '',
      startTime: '',
      endTime: '',
      form: {
        scheduleId: '',
        memberId: '',
        participants: null,
        branch: process.env.VUE_APP_BRANCH,
        organizationName: '',
        contactFirstname: '',
        contactLastname: '',
        contactTel: '',
        contactEmail: '',
        note: '',
        verifyNote: ''
      },
      items: []
    }
  },
  computed: {
    ...mapGetters({
      isRegistered: 'booking/isRegistered',
      group: 'booking/group',
      isMember: 'booking/isMember',
      branch: 'booking/branch',
      formStore: 'bookingGroup/form',
      scheduleStore: 'bookingGroup/schedule'
    })
  },
  async mounted () {
    this.form = this.formStore
    this.handleBranch()
    await this.getAllSchedule()
    this.checkIsMember()
    if (this.isRegistered) {
      const { date, startTime, endTime, slot, max } = this.scheduleStore
      this.date = date
      this.startTime = startTime
      this.endTime = endTime
      this.slot = slot
      this.max = max

      this.isBookingForm = true
      this.isBooking = false
    }
  },
  methods: {
    ...mapActions({
      showErrorMessage: 'notification/showErrorMessage',
      closeErrorMessage: 'notification/closeErrorMessage',
      setForm: 'bookingGroup/setForm',
      setIsRegistered: 'booking/setIsRegistered',
      setIsMember: 'booking/setIsMember',
      setIsFromGroup: 'bookingGroup/setIsFromGroup',
      setFacebookId: 'webExternal/setFacebookId',
      setSchedule: 'bookingGroup/setSchedule'
    }),
    changeLanguage (type) {
      this.$i18n.locale = type
      this.isLoading = true
      this.$nextTick(() => {
        this.isLoading = false
      })
    },
    checkIsMember () {
      if (this.isMember) {
        const { member } = JSON.parse(localStorage.getItem('member'))
        this.member = member
        this.form.memberId = member.memberId
        this.form.contactFirstname = member.firstname
        this.form.contactLastname = member.lastname
        this.form.contactTel = member.tel
        this.form.contactEmail = member.email
      }
    },
    checkConfirm () {
      const valid = this.$refs.form.validate()
      if (valid) {
        if (this.form.scheduleId && this.startTime && this.endTime) {
          if (this.member) {
            this.setForm(this.form)
            this.isBooking = false
            window.scrollTo(0, 0)
            this.isBookingForm = true
          } else {
            this.setForm(this.form)
            this.$router.push('/liff/register')
          }
        } else {
          this.showErrorMessage({
          type: 'warning',
          message: this.$t('global.validate')
        })
        setTimeout(() => {
          this.closeErrorMessage()
        }, 4000)
        }
      } else {
        this.showErrorMessage({
          type: 'warning',
          message: this.$t('global.validate')
        })
        setTimeout(() => {
          this.closeErrorMessage()
        }, 4000)
      }
    },
    async getSchedule (event) {
      try {
        this.isLoading = true
        const { data: { data } } = await this.axios.get(`${process.env.VUE_APP_API_URL}/schedule/date/search?date=${event.date}&branch=${process.env.VUE_APP_BRANCH}&type=normal`)
        // const { data: { data } } = await this.axios.get(`${process.env.VUE_APP_API_URL}/schedule/date/search?date=${event}`)
        this.items = data
        this.dateMock = event.date
        this.isSchedule = true
      } catch (error) {
        this.error = error
      } finally {
        this.isLoading = false
      }
    },
    async getAllSchedule () {
      try {
        this.isLoading = true
        this.reRender = false
        const { data: { data, additional } } = await this.axios.get(`${process.env.VUE_APP_API_URL}/schedule?branch=${process.env.VUE_APP_BRANCH}&type=normal`)
        this.allSchedule = data
        this.allDateFull = additional.full
        this.allDateAvailable = additional.available
      } catch (error) {
        this.error = error
      } finally {
        this.isLoading = false
        this.reRender = true
      }
    },
    confirmSchedule () {
      const el = this.$refs.booking
      setTimeout(() => {
        el.scrollIntoView({
        behavior: 'smooth',
        block: 'start'
      })
      }, 200)
      this.isSchedule = false
    },
    async onAddOrder (event) {
      try {
        this.isLoading = true
        const { form, file } = event
        this.form = form
        this.form.branch = process.env.VUE_APP_BRANCH
        if (file) {
          const formData = new FormData()
          formData.append('file', file)
          const { data: { data } } = await this.axios({
            method: 'post',
            url: `${process.env.VUE_APP_API_URL}/upload`,
            headers: { 'Content-Type': 'multipart/form-data' },
            data: formData
          })
          this.form.verifyNote = data.publicUrl
        }
        this.form.memberId = this.member.memberId
        await this.axios.post(`${process.env.VUE_APP_API_URL}/order-group`, this.form)

        this.setForm({
          scheduleId: '',
          memberId: '',
          participants: null,
          branch: process.env.VUE_APP_BRANCH,
          organizationName: '',
          contactFirstname: '',
          contactLastname: '',
          contactTel: '',
          contactEmail: '',
          note: '',
          verifyNote: ''
        })
        this.setIsRegistered(false)

        this.$router.push('/liff/history')
      } catch (error) {
        this.setForm({
          scheduleId: '',
          memberId: '',
          participants: null,
          branch: process.env.VUE_APP_BRANCH,
          organizationName: '',
          contactFirstname: '',
          contactLastname: '',
          contactTel: '',
          contactEmail: '',
          note: '',
          verifyNote: ''
        })
        this.setIsRegistered(false)
        this.isBooking = true
        this.isBookingForm = false
        this.showErrorMessage({
          type: 'warning',
          message: this.$t('booking.slotMax')
        })
        setTimeout(() => {
          this.closeErrorMessage()
        }, 4000)
      } finally {
        this.isLoading = false
      }
    },
    setTime (scheduleId, startTime, endTime, max, slot) {
      this.form.scheduleId = scheduleId
      this.startTime = startTime
      this.endTime = endTime
      this.date = this.dateMock
      this.max = max
      this.slot = slot
      this.setSchedule({
        date: this.date,
        startTime: this.startTime,
        endTime: this.endTime,
        max: this.max,
        slot: this.slot
      })
      this.confirmSchedule()
    },
    handleBranch () {
      if (process.env.VUE_APP_BRANCH === 'sathorn') {
        this.backgroundColor = '#3BAEF8'
      } else {
        this.backgroundColor = '#FFACB7'
      }
    },
    formatDate (date) {
      return formatDate.default(date)
    },
    formatSchedule (startTime, endTime) {
      if (startTime === '00:00' && endTime === '23:59') {
        return 'Allday'
      }
      return `${startTime} - ${endTime}`
    }
  }
}
</script>
<style lang="scss" scoped>
.header {
  background: #f4f4f4;
}
</style>
